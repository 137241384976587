html {
  min-height: 100%;
  font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue",
    sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f1f1f1;
  background-size: 100%;
  background-position: bottom;
  min-height: 100%;
}

/* form {
  padding: 20px;
  margin-top: 60px;
} */

form {
  display: flex;
  flex-direction: column;
  width: 400px;
  min-width: 100px;
  min-height: 100px;
  padding: 20px 40px 40px 40px;
  border-radius: 6px;
  background-color: #fefefe;
  margin-top: 60px;
  text-align: left;
}

form > h1 {
  display: flex;
  justify-content: center;
  font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 2em;
  font-weight: lighter;
  margin-top: 0.25em;
  color: #222;
  letter-spacing: 2px;
}
.custom-form {
  display: flex;
  flex-direction: column;
  width: 400px;
  min-width: 100px;
  min-height: 200px;
  padding: 20px 40px 40px 40px;
  border-radius: 6px;
  background-color: #fefefe;
  margin-top: 60px;
  text-align: left;
}

.google-login-form {
  display: flex;
  flex-direction: column;
  width: 450px;
  min-width: 100px;
  min-height: 200px;
  padding: 20px 40px 40px 40px;
  border-radius: 6px;
  background-color: #fefefe;
  margin-top: 60px;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #302d2d;
  animation: popup 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.info {
  padding-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

label {
  margin-bottom: 0.5em;
  color: #444;
  font-weight: lighter;
}

input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}
input,
select {
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid #d6d1d5;
  margin-top: 5px;
}
select {
  display: block;
  width: 100%;
  height: 35px;
}

.card {
  width: 500px;
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
  margin-top: 20px;
}
.navbar {
  background-color: black;
}

.navCustom {
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 5px;
}
.navCustom:hover {
  color: red;
}

.navbar-brand {
  padding-left: 20px;
}

.no-bullets {
  list-style-type: none;
}

.navBtnCustom {
  background-color: white !important;
  color: black !important;
  padding: 7px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 15px;
  margin-top: -5px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
}

.card {
  width: 1000px;
  height: auto;
}

.item-centered {
  margin-top: 100px;
  margin-bottom: 40px;
}

#divtoprnt {
  background-color: "#f5f5f5";
  width: "210mm";
  min-width: "297mm";
  min-height: "auto";
  margin-right: "auto";
}

.travel {
  display: none;
}

.volunteer {
  display: none;
}

.representative {
  display: none;
}
